import HomePage from 'domain/home/components/HomePage';
import React from 'react';
import MyCompanyPage from 'domain/company/components/MyCompanyPage';
import TransactionsPage from 'domain/transactions/components/TransactionsPage';
import SupportPage from 'domain/support/components/SupportPage';
import SellingToolsPage from 'domain/sellingTools/components/SellingToolsPage';
import MyClientsPage from 'domain/myClients/components/MyClientsPage';
import CalendarPage from 'domain/calendar/components/CalendarPage';
import SendToGCPage from 'domain/sendToGc/components/SendToGCPage';
import DocumentItemDetailPage from 'components/shared/list/DocumentItemDetailPage';
import NewsArticleAdminPage from 'domain/home/components/admin/NewsArticleAdminPage';
import AdminPage from 'domain/admin/components/AdminPage';
import CalendarEventAdminPage from 'domain/calendar/components/admin/CalendarEventAdminPage';
import ProfilePage from 'domain/profile/components/ProfilePage';
import RequireAdmin from 'domain/auth/components/RequireAdminRole';
import AlertAdminPage from 'domain/alert/components/AlertAdminPage';
import UniqueTransactionPage from 'domain/transactions/components/UniqueTransactionPage';
import SendToGcAdminPage from 'domain/sendToGc/components/admin/SendToGcAdminPage';
import EquisoftPage from 'domain/equisoft/component/EquisoftPage';
import UniqueQuizPage from 'domain/company/components/ufcCenter/UniqueQuizPage';

export declare type RouteParams = {
  index?: true;
  path: string;
  Element: React.FC;
  children?: RouteParams[];
};

export enum RoutesPath {
  SEND_TO_GC = 'sendToGc',
  TRANSACTIONS = 'transactions',
  UNIQUE_TRANSACTION = 'transactions/:id',
  MY_CLIENTS = 'myClients',
  UNIQUE_UFC_QUIZ = 'myCompany/compliance/ufc-center/quizzes/:id',
  SELLING_TOOLS = 'sellingTools',
  MY_COMPANY = 'myCompany',
  SUPPORT = 'support',
  CALENDAR = 'calendar',
  ADMIN = 'admin',
  PROFIL = 'profile',
  HOME = '*',
  EQUISOFT = 'equisoft'
}

enum AdminSubRoutesPath {
  NEWS = 'news',
  CALENDAR = 'calendar',
  ALERTS = 'alerts',
  SEND_TO_GC = 'sendToGc',
}

enum SellingToolsSubRoutesPath {
  LIFE_INSURANCE = 'lifeInsurance',
  LIVING_BENEFITS = 'livingBenefits',
  PROTEK_SOLUTIONS = 'protekSolutions',
  MORTGAGE = 'mortgage',
  INVESTMENTS = 'investments',
  GROUP_INSURANCE = 'groupInsurance',
  ENTREPRENEUR_MARKET = 'entrepreneurMarket',
  PRIVATE_MARKET = 'privateMarket',
  TRAVEL_INSURANCE = 'travelInsurance',
}

const sellingToolsSubRoutes = Object.values(SellingToolsSubRoutesPath).map(path => ({
  path,
  Element: DocumentItemDetailPage,
}));

enum CompanyItemSubRoutesPath {
  COMPLIANCE = 'compliance',
  BUSINESS_DEVELOPMENT = 'businessDevelopment',
  BUSINESS_PRACTICES = 'businessPractices',
  CONTRACT_MANAGEMENT = 'contractManagement',
}

const companyItemSubRoutesPath = Object.values(CompanyItemSubRoutesPath).map(path => ({
  path,
  Element: DocumentItemDetailPage,
}));

enum ClientsItemSubRoutesPath {
  TOOLS = 'tools',
}

const clientsItemSubRoutesPath = Object.values(ClientsItemSubRoutesPath).map(path => ({
  path,
  Element: DocumentItemDetailPage,
}));

const adminSubRoutes: RouteParams[] = [
  {
    path: AdminSubRoutesPath.NEWS,
    Element: () => (
      <RequireAdmin>
        <NewsArticleAdminPage />
      </RequireAdmin>
    ),
  },
  {
    path: AdminSubRoutesPath.CALENDAR,
    Element: () => (
      <RequireAdmin>
        <CalendarEventAdminPage />
      </RequireAdmin>
    ),
  },
  {
    path: AdminSubRoutesPath.ALERTS,
    Element: () => (
      <RequireAdmin>
        <AlertAdminPage />
      </RequireAdmin>
    ),
  },
  {
    path: AdminSubRoutesPath.SEND_TO_GC,
    Element: () => (
      <RequireAdmin>
        <SendToGcAdminPage />
      </RequireAdmin>
    ),
  },
];

const rootRoutes: RouteParams[] = [
  {
    path: RoutesPath.HOME,
    Element: HomePage,
    index: true,
  },
  {
    path: RoutesPath.SEND_TO_GC,
    Element: SendToGCPage,
  },
  {
    path: RoutesPath.TRANSACTIONS,
    Element: TransactionsPage,
  },
  {
    path: RoutesPath.UNIQUE_TRANSACTION,
    Element: UniqueTransactionPage,
  },
  {
    path: RoutesPath.MY_CLIENTS,
    Element: MyClientsPage,
    children: clientsItemSubRoutesPath,
  },
  {
    path: RoutesPath.SELLING_TOOLS,
    Element: SellingToolsPage,
    children: sellingToolsSubRoutes,
  },
  {
    path: RoutesPath.MY_COMPANY,
    Element: MyCompanyPage,
    children: companyItemSubRoutesPath,
  },
  {
    path: RoutesPath.UNIQUE_UFC_QUIZ,
    Element: UniqueQuizPage,
  },
  {
    path: RoutesPath.SUPPORT,
    Element: SupportPage,
  },
  {
    path: RoutesPath.CALENDAR,
    Element: CalendarPage,
  },
  {
    path: RoutesPath.ADMIN,
    Element: () => (
      <RequireAdmin>
        <AdminPage />
      </RequireAdmin>
    ),
    children: adminSubRoutes,
  },
  {
    path: RoutesPath.PROFIL,
    Element: ProfilePage,
  },
  {
    path: RoutesPath.EQUISOFT,
    Element: EquisoftPage,
  },
];

export default rootRoutes;
