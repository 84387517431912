import TextInput from "components/inputs/TextInput"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { QuestionType } from "types/quiz"
import { QuestionProps } from "./QuestionInput"

function ShortTextInput({ question, stateValue, setStateValue, setErrorMessages } : QuestionProps) {
    const { t } = useTranslation('company');

    if (question.type !== QuestionType.ShortText)
        return <></>

    useEffect(() => {
        if (stateValue) {
            const str = stateValue as string
            if (question.minLength && str.trim().length < question.minLength)
                setErrorMessages(x => [...x, t("textTooShort")])
            else if (question.maxLength && str.trim().length > question.maxLength)
                setErrorMessages(x => [...x, t("textTooLong")])
        } else if (stateValue === "") {
            setStateValue(undefined)
        }
    }, [stateValue])

    function handleChange(e: React.FormEvent<HTMLInputElement>) {
        setStateValue(e.currentTarget.value)
    }

    return (
        <TextInput 
            label={""} 
            value={(stateValue ?? "") as string}
            onChange={handleChange}
            formNoValidate
        />
    )
}

export default ShortTextInput