import { Fragment } from "react"
import styles from 'styles/domain/company/quiz-inputs.module.scss';
import { QuestionType } from "types/quiz";
import { QuestionProps } from "./QuestionInput";

function MultipleChoiceInput({ question, stateValue, setStateValue } : QuestionProps) {
    if (question.type !== QuestionType.Choice)
        return <></>

    const { multipleChoice, radioInput } = styles;
    return (
        <div className={multipleChoice}>
            {question.choices.map((answer, i) => (
                <Fragment key={i}>
                    <input id={`answer-${i}`} type="radio" className={radioInput} name="question" checked={Number(stateValue) === i} onChange={() => setStateValue(i)} />
                    <label htmlFor={`answer-${i}`}>{answer}</label>
                </Fragment>
            ))}
        </div>
    )
}

export default MultipleChoiceInput