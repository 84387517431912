import Icon from "components/icons/Icon";
import Button from "components/inputs/Button"
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import styles from 'styles/domain/company/unique-quiz-page.module.scss';
import { Quiz } from "types/quiz";

type Props = {
    quiz: Quiz
    isPassed: boolean
}

function QuizResult({ quiz, isPassed }: Props) {
    const navigate = useNavigate();

    const { t } = useTranslation('company');

    const { container, subTitle, actionsContainer, icon, markdown } = styles;

    return (
        <div className={container}>
            <div>
                <h2 className={subTitle}>
                    <div className={icon}>
                        <Icon name={isPassed ? "successSeverity" : "dangerSeverity"} />
                    </div>
                    <p>{t("result")}</p>
                </h2>
                <ReactMarkdown className={markdown} children={t(isPassed ? "successMessage" : "failMessage")
                    .replace("%%TITLE%%", quiz.title)
                    .replace("%%PASSING_GRADE%%", `${quiz.passingGradePercent}`)} 
                />
            </div>

            <div className={actionsContainer}>
                {isPassed ? (
                    <Button onClick={() => navigate("/myCompany")}>{t("goToHomePage")}</Button>
                ) : (
                    <Button onClick={() => window.location.reload()}>{t("restart")}</Button>
                )}
            </div>
        </div>
    )
}

export default QuizResult