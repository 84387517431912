import { useTranslation } from 'react-i18next';
import styles from 'styles/domain/company/quiz-inputs.module.scss';
import { QuestionType } from 'types/quiz';
import { QuestionProps } from './QuestionInput';

function TrueOrFalseInput({ question, stateValue, setStateValue } : QuestionProps) {
    const { t } = useTranslation('company');

    if (question.type !== QuestionType.TrueOrFalse)
        return <></>
    
    return (
        <div className={styles.multipleChoice}>
            <input id={"answer-true"} type="radio" name="question" checked={stateValue === true} onChange={() => setStateValue(true)} />
            <label htmlFor={"answer-true"}>{t("true")}</label>
            <input id={"answer-false"} type="radio" name="question" checked={stateValue === false} onChange={() => setStateValue(false)} />
            <label htmlFor={"answer-false"}>{t("false")}</label>
        </div>
    )
}

export default TrueOrFalseInput