import { Answer } from "types/quiz";
import { callApi } from "utils/httpClient";

const gcServiceUrl = `${process.env.REACT_APP_UFC_SERVICE_URL}`;

async function IsQuizPassing(id: string, answers: Answer[]): Promise<boolean> {
    const { data } = await callApi('post', gcServiceUrl, "ufcquiz/submit", {
        Responses: answers,
        QuizId: id
    });
    return data.succeeded;
}

export default IsQuizPassing;