import { Navigate, useParams } from "react-router-dom";
import styles from 'styles/domain/company/unique-quiz-page.module.scss';
import titleStyles from 'styles/components/pages.module.scss';
import useGetQuiz from "../../hooks/useGetQuiz";
import LoadingSpinner from "components/shared/LoadingSpinner";
import QuizForm from "./QuizForm";
import { useEffect, useState } from "react";
import QuizResult from "./QuizResult";
import IsQuizPassing from "../../api/IsQuizPassing";
import QuizPresentation from "./QuizPresentation";
import { Answer } from "types/quiz";

enum Page {
    Presentation,
    Form,
    Result,
}

function UniqueQuizPage() {
    const { id } = useParams();    

    const [currentPage, setCurrentPage] = useState(Page.Presentation)
    const [isPassed, setIsPassed] = useState<boolean>()
    
    const { data, isError, isLoading } = useGetQuiz(id!);

    useEffect(() => {
        if (isPassed != undefined)
            setCurrentPage(Page.Result)
    }, [isPassed])

    if (!data) { return <></>}

    const getCurrentComponent = () => {
        switch(currentPage) {
            case Page.Presentation: return <QuizPresentation onQuizStart={() => setCurrentPage(Page.Form)} {...data} />
            case Page.Form: return <QuizForm onSubmit={quizCallBack} {...data} />
            case Page.Result: return <QuizResult isPassed={isPassed!} {...data} />
        }
    }

    const quizCallBack = async (result: Answer[]) => {
        setIsPassed((await IsQuizPassing(id!, result)))
    }

    if (isLoading)
        return <div className={styles.spinnerContainer}><LoadingSpinner /></div>

    const { title, titleNoBorder } = titleStyles;

    return (
        <>
            <h1 className={`${title} ${titleNoBorder}`}>{data!.quiz.title}</h1>
            {getCurrentComponent()}
        </>
    )
}

export default UniqueQuizPage