import Icon from 'components/icons/Icon';
import { RouterLink } from 'components/navigation';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'styles/domain/support/tabs.module.scss';

export type TabsType = {
  label: string;
  index: number;
  Component: ReactElement;
};

type Props = {
  tabs: TabsType[];
  isTabsLeftAligned?: boolean;
  selectedTabIndex?: number;
  noBorder?: boolean;
};

function Tabs({ tabs = [], isTabsLeftAligned, selectedTabIndex, noBorder = false }: Props) {
  const { t } = useTranslation('genericItem');
  const [selectedTab, setSelectedTab] = useState(selectedTabIndex ?? 0);
  const { Component } = tabs[selectedTab];
  const { tabsList, tabsListLeftAligned, tabsNoBorder, activeButton, tabButton, ufcQuizList, item, name } = styles;

  return (
    <div>
      <div className={`${tabsList} ${isTabsLeftAligned ? tabsListLeftAligned : ''}  ${noBorder ? tabsNoBorder : ''}`}>
        {tabs.map(({ index, label }) => (
          <button
            className={`${tabButton} ${selectedTab === index ? activeButton : ''}`}
            onClick={() => setSelectedTab(index)}
            key={index}
          >
            {label}
          </button>
        ))}
      </div>
      {Component}

      {/* TODO Change for less hard coded way asap*/}
      {tabs[selectedTab].label === t("ufcCenter") && (
        <div className={ufcQuizList}>
          <h2>Formations</h2>
          <RouterLink className={item} to={'ufc-center/quizzes/ace44f3d-00d6-4824-81d3-db9ebea9d386'}>
            <Icon name="link" />
            <p className={name}>Formation Blanchiment d'argent</p>
          </RouterLink>
        </div>
      )}
    </div>
  );
}

export default Tabs;
