import { Quiz } from "types/quiz";
import styles from 'styles/domain/company/unique-quiz-page.module.scss';
import Button from "components/inputs/Button";
import ReactMarkdown from 'react-markdown';
import { useTranslation } from "react-i18next";

type Props = {
    readonly quiz: Quiz;
    readonly fullName: string;
    readonly email: string;
    readonly licenseNumber: string | null;
    readonly onQuizStart: () => void;
}

function QuizPresentation({ quiz, fullName, email, licenseNumber, onQuizStart} : Props) {
    const { t } = useTranslation('company');

    const { container, actionsContainer, markdown } = styles;
    return (
        <div className={container}>
            <div>
                <div className={markdown}>
                    <ReactMarkdown>{quiz.descriptionMD}</ReactMarkdown>
                </div>
                <span>
                    <b>{t('noteTitle')}</b> 
                    {t('noteText')
                        .replace("%%name%%", fullName)
                        .replace("%%email%%", email)
                        .replace("%%#amf%%", licenseNumber ?? "aucun numéro d'AMF lié à ce compte")
                    } 
                </span>
            </div>
            
            
            <div className={actionsContainer}>
                <Button onClick={onQuizStart}>{t("startQuiz")}</Button>
            </div>
        </div>
    )
}

export default QuizPresentation