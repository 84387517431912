import { callApi } from 'utils/httpClient';
import { getQuizResponseSchema } from 'types/quiz';

const gcServiceUrl = `${process.env.REACT_APP_UFC_SERVICE_URL}`;

async function getQuiz(id: string) {
    const { data } = await callApi('get', gcServiceUrl, `/ufcquiz/${id}`);
    return getQuizResponseSchema.parse(data);
}

export default getQuiz;