import { ConfirmationModalText } from 'components/typography/ConfirmationModalText';
import { useModalContext } from 'hooks/useModalContext';
import { SyntheticEvent, useEffect, useLayoutEffect, useState } from 'react';
import styles from 'styles/domain/company/unique-quiz-page.module.scss';
import { Answer, Quiz } from 'types/quiz';
import Button from 'components/inputs/Button';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import RequiredMarker from 'components/typography/RequiredMarker';
import QuestionInput from './inputs/QuestionInput';

type Props = {
    readonly quiz: Quiz;
    readonly onSubmit: (answers: Answer[]) => void;
}

function QuizForm({ quiz, onSubmit }: Props) {
    const { showModal } = useModalContext();

    const [questionIndex, setQuestionIndex] = useState(0);
    const [currentAnswer, setCurrentAnswer] = useState<Answer>();
    const [myAnswers, setMyAnswers] = useState<Answer[]>([]);

    const [errorMessages, setErrorMessages] = useState<string[]>([])

    const currentQuestion = quiz?.questions[questionIndex]
    const questionsLength = quiz?.questions.length
    const isCurrentQuestionLast = questionIndex >= questionsLength! - 1

    const { t } = useTranslation('company');

    useLayoutEffect(() => {
        setErrorMessages([])
    }, [questionIndex, currentAnswer])

    useEffect(() => {
        setCurrentAnswer(myAnswers[questionIndex])
    }, [questionIndex]);

    useEffect(() => {
        if (currentAnswer != undefined) {
            setMyAnswers(x => {
                x[questionIndex] = currentAnswer
                return x
            })
        }
    }, [currentAnswer]);

    function endQuiz() {
        showModal({
            createComponent: () => <ConfirmationModalText title={t("confirmation")} message={t("confirmMessage")} />,
            cancellable: true,
            showCloseBtn: true,
            submitText: t("yes"),
            onSubmit: () => { onSubmit(myAnswers) }
        })
    }

    function nextQuestion() {
        setQuestionIndex(x => x + 1)
    }

    function previousQuestion() {
        if (questionIndex <= 0)
            return setQuestionIndex(0)

        setQuestionIndex(x => x - 1)
    }

    function handleSubmit(e: SyntheticEvent) {
        e.preventDefault()

        if (!validateAnswer() || errorMessages.length > 0)
            return

        if (isCurrentQuestionLast)
            endQuiz()
        else
            nextQuestion()
    }

    function validateAnswer() {
        let isFormValid = true
        if (currentAnswer === undefined) {
            setErrorMessages(x => [...x, t("answerRequired")])
            isFormValid = false
        }
            
        return isFormValid
    }

    function getQuestionText() {
        return (
            <div className={questionTitle}>
                <span>{`${questionIndex + 1}/${questionsLength}`}</span>
                <span className={questionText}>
                    {currentQuestion!.text}
                    <RequiredMarker isRequired={true} />
                </span>
            </div>
        )
    }

    const { container, actionsContainer, questionContainer, questionText, questionTitle } = styles;

    return (
        <form id="quiz-question" className={container} onSubmit={handleSubmit}>
            <div className={questionContainer}>
                {getQuestionText()}

                <QuestionInput 
                    question={currentQuestion} 
                    stateValue={currentAnswer} 
                    setStateValue={setCurrentAnswer} 
                    setErrorMessages={setErrorMessages} 
                />

                {errorMessages.map((message, i) => (
                    <ErrorMessage key={i} errorMessage={message} />
                ))}
            </div>
            
            <div className={actionsContainer}>
                <Button type='button' onClick={previousQuestion} disabled={questionIndex <= 0}>{t("previousQuestion")}</Button>
                <Button type='submit'>{t(isCurrentQuestionLast ? "submit" : "nextQuestion")}</Button>
            </div>
        </form>
    )
}

export default QuizForm