import { Answer, Question, QuestionType } from "types/quiz"
import MultipleChoiceInput from "./MultipleChoiceInput"
import TrueOrFalseInput from "./TrueOrFalseInput"
import ShortTextInput from "./ShortTextInput"
import { SetStateAction } from "react"

export type QuestionProps = {
    question: Question
    stateValue: Answer | undefined
    setStateValue: React.Dispatch<SetStateAction<Answer | undefined>>
    setErrorMessages: React.Dispatch<SetStateAction<string[]>>
}


function QuestionInput(props: QuestionProps) {
    switch(props.question.type) {
        case QuestionType.Choice:
            return <MultipleChoiceInput {...props} />
        case QuestionType.TrueOrFalse:
            return <TrueOrFalseInput {...props} />
        case QuestionType.ShortText:
            return <ShortTextInput {...props} />
    }
}

export default QuestionInput