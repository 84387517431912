import { z } from 'zod';

export enum QuestionType {
    Choice = "MultipleChoiceQuestion",
    TrueOrFalse = "TrueOrFalseQuestion",
    ShortText = "ShortTextQuestion",
}

const baseQuestionSchema = z.object({
    text: z.string(),
    type: z.nativeEnum(QuestionType)
});

const questionSchema = z.union([
    baseQuestionSchema.extend({
        type: z.literal(QuestionType.Choice),
        choices: z.array(z.string())
    }),
    baseQuestionSchema.extend({
        type: z.literal(QuestionType.TrueOrFalse)
    }),
    baseQuestionSchema.extend({
        type: z.literal(QuestionType.ShortText),
        maxLength: z.nullable(z.number()),
        minLength: z.nullable(z.number())
    })
])

export const quizSchema = z.object({
    title: z.string(),
    passingGradePercent: z.number(),
    descriptionMD: z.string(),
    questions: z.array(questionSchema)
});

export type Quiz = z.infer<typeof quizSchema>

export const getQuizResponseSchema = z.object({
    quiz: quizSchema,
    email: z.string(),
    fullName: z.string(),
    licenseNumber: z.nullable(z.string())
})

export type Question = z.infer<typeof questionSchema>

export type Answer = boolean | string | number